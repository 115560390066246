










import {
  apply,
  pipe,
  ifElse,
  always,
  thunkify,
  when,
  isNil,
  gt,
  identity,
  reduce,
  where,
  prop,
  __,
  props,
  map,
} from "ramda";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { notEmpty } from "@/utils/helpers";

/* eslint-disable no-unused-vars */
import {
  BreadcrumbModel,
  BreadcrumbReducer,
  handleCrumb,
  handleHide,
  hideCrumbs,
  measure,
} from "@/utils/breadcrumbs";
/* eslint-enable no-unused-vars */
import { reduceIdx } from "@/utils/helpers";
import {
  getTailCrumbsWidth,
  getTotalBreadcrumbsWidth,
  shouldCollapse,
} from "@/utils/breadcrumbs";

@Component
export default class Breadcrumbs extends Vue {
  private crumbs: Array<BreadcrumbModel> = [];
  private handleCrumbs = pipe(this.measure, this.collapse);

  DIVIDER_WIDTH = 12;

  @Ref("breadcrumbs") breadcrumbs!: any;
  @Prop({ type: Array, default: [] }) items!: Array<string>;

  @Watch("items", { immediate: true })
  process(items: Array<string>) {
    pipe(
      ifElse(
        always(isNil(this.breadcrumbs)),
        pipe(thunkify(this.process), requestAnimationFrame, always([])),
        this.handleCrumbs
      ),
      when(notEmpty, (crumbs) => {
        this.crumbs = crumbs;
      })
    )(items);
  }

  shouldCollapse(item: BreadcrumbModel) {
    return shouldCollapse(this.crumbs, item);
  }

  private collapse(items: Array<BreadcrumbModel>): Array<BreadcrumbModel> {
    const crumbsWidth = getTotalBreadcrumbsWidth(this.DIVIDER_WIDTH, items);
    const offsetWidth = this.breadcrumbs.$el.offsetWidth;

    return ifElse(
      always<any>(gt(crumbsWidth, offsetWidth)),
      pipe(
        reduce<any, any>(this.handleCrumb, { crumbs: [], crumbsWidth }),
        ifElse(
          where({
            crumbsWidth: gt(__, offsetWidth),
          }),
          pipe(props(["crumbs", "crumbsWidth"]), apply<any, any>(this.hide)),
          prop("crumbs")
        )
      ),
      identity
    )(items);
  }

  private hide(items: Array<BreadcrumbModel>, width: number) {
    return pipe(
      reduceIdx(
        handleHide<any, any>(
          this.breadcrumbs.$el.offsetWidth,
          this.DIVIDER_WIDTH,
          items
        ),
        {
          crumbs: items,
          crumbsWidth: getTailCrumbsWidth(width, items),
        }
      ),
      prop("crumbs"),
      hideCrumbs(items)
      //@ts-ignore
    )(items);
  }

  private measure(items: Array<string>): Array<BreadcrumbModel> {
    return map(measure(document), items);
  }

  private handleCrumb(breadcrumbs: BreadcrumbReducer, item: BreadcrumbModel) {
    const BASE_WIDTH = 10;

    return handleCrumb(
      breadcrumbs,
      BASE_WIDTH,
      this.breadcrumbs.$el.offsetWidth,
      item
    );
  }
}












































import { Observer } from "mobx-vue";
import { reaction } from "mobx";
import { getSnapshot } from "mobx-state-tree";
import { Component, Emit, Mixins } from "vue-property-decorator";
import { ContentItemModel } from "dc-extensions-sdk"; // eslint-disable-line no-unused-vars
import { CardModel } from "@/store/CardModel"; // eslint-disable-line no-unused-vars

import TreeView from "./TreeView/TreeView.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import Alert from "./Alert.vue";
import ShowAlert from "@/mixins/ShowAlert.mixin";

import store from "@/store/DynamicContent";
import TreeStore from "@/store/Tree";
import { ifElse, lte } from "ramda";

@Observer
@Component({
  components: { Alert, TreeView, ErrorBox },
})
export default class ChooserOverlay extends Mixins(ShowAlert) {
  public store = store;
  public tree = TreeStore;
  public watcher = reaction(
    () => this.tree.selectedNodes.length,
    this.checkLength
  );

  destroyed() {
    this.watcher();
  }

  @Emit("add")
  add() {
    return getSnapshot(this.tree.selectedNodes);
  }

  @Emit("cancel")
  cancel(e: Event) {
    return e;
  }

  checkLength(length: number) {
    ifElse(
      lte(this.store.remainingItems),
      () => {
        this.showAlert("Maximum number of content items has been reached");
        this.tree.disableTree();
      },
      this.tree.enableTree
    )(length);
  }
}

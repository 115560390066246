
























































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { reaction } from "mobx";
import { Observer } from "mobx-vue";
import {
  always,
  and,
  equals,
  forEach,
  gt,
  ifElse,
  not,
  or,
  pipe,
  subtract,
  when,
  where,
  __,
} from "ramda";
import TreeStore from "@/store/Tree";
// eslint-disable-next-line no-unused-vars
import { INode } from "@/store/Node";
import DynamicContent from "@/store/DynamicContent";
import {
  hasChildren,
  nestingLevels,
  paddingLeft,
  preventSelection,
  previousNode,
} from "@/utils/tree";
import { notError } from "@/utils/helpers";
import { getPadding, isInvalidType, previousNodeDisabled } from "@/utils/tree";
import Alert from "@/mixins/ShowAlert.mixin";
import StatusIcon from "./StatusIcon.vue";

@Observer
@Component({
  components: {
    StatusIcon,
  },
})
export default class TreeNode extends Mixins(Alert) {
  @Prop({ type: Object, required: true })
  node!: INode;

  paddingAmount: number = 26;
  treeStore = TreeStore;
  dynamicContent = DynamicContent;
  allowedTypes: string[] = [];
  isSelected: boolean = false;
  loadingChildren: boolean = false;
  preventSelection: boolean = false;
  tooltipVisible: boolean = false;
  watchers: Function[] = [];

  get paddingLeft(): string {
    return paddingLeft(
      this.node.nestingLevel,
      this.isInvalid,
      this.paddingAmount
    );
  }

  get isArchived() {
    return equals(this.node.status, "ARCHIVED");
  }

  get isInvalid() {
    return or(
      isInvalidType(this.dynamicContent.allowedTypes, this.node.contentTypeUri),
      this.isArchived
    );
  }

  get nestingLevels(): number[] {
    return nestingLevels(this.node.path as INode[], this.node.nestingLevel);
  }

  get previousNodeDisabled() {
    const prevNode = previousNode(
      TreeStore.rootNode as INode,
      this.node
    ) as INode;

    return and(
      previousNodeDisabled(
        TreeStore.rootNode as INode,
        this.dynamicContent.allowedTypes,
        this.node
      ),
      gt(this.node.nestingLevel, prevNode.nestingLevel)
    );
  }

  get selected() {
    return this.treeStore.selectedNodes;
  }

  get showStatusIcon(): boolean {
    return not(equals(this.node.publishingStatus, "NONE"));
  }

  async created() {
    this.isSelected = this.treeStore.isSelected(this.node.id);
    this.watchers = [
      reaction(
        () => this.treeStore.selectedNodes.length,
        () => {
          this.preventSelection = preventSelection(
            this.isSelected,
            this.dynamicContent.remainingItems,
            this.treeStore.selectedNodes
          ) as boolean;
        },
        { fireImmediately: true }
      ),
    ];
  }

  destroyed() {
    forEach((watcher) => watcher(), this.watchers);
  }

  getTreeLinePadding(nestingLevel: number) {
    return pipe(subtract(__, 1), getPadding(this.paddingAmount))(nestingLevel);
  }

  async loadChildren() {
    this.loadingChildren = true;

    ifElse(notError, this.toggleChildren, () =>
      this.showAlert("Could not load children")
    )(await this.node.loadChildren());

    this.loadingChildren = false;
  }

  select(selected: boolean) {
    when(
      where({
        isInvalid: equals(false),
        preventSelection: equals(false),
      }),
      () => {
        this.isSelected = selected;
        ifElse(
          always(selected),
          this.treeStore.selectNode,
          this.treeStore.deselectNode
        )(this.node.id);
      }
    )({
      isInvalid: this.isInvalid,
      preventSelection: this.preventSelection,
    });
  }

  toggleChildren() {
    ifElse(
      hasChildren,
      (node) => node.showChildren(!node.childrenVisible),
      this.loadChildren
    )(this.node);
  }
}






















import { Component, Vue } from "vue-property-decorator";
import { Observer } from "mobx-vue";

import Global from "@/store/Global";

@Observer
@Component
export default class Alert extends Vue {
  public global = Global;
}

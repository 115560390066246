













































import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import { CardModel } from "@/store/CardModel"; // eslint-disable-line no-unused-vars
import { ContentItemModel } from "@/store/FieldModel"; // eslint-disable-line no-unused-vars

import store from "@/store/DynamicContent";

@Observer
@Component
export default class Chip extends Vue {
  public store = store;
  public noop = () => {};
  public hideTooltip: boolean = false;

  @Prop(Object) value!: CardModel;

  get isEdit() {
    return !this.value.isEmpty();
  }

  get isDisabled() {
    return this.value.index === this.store.maxItems;
  }

  get tooltip() {
    if (this.isEdit) {
      return [
        ...this.value.path,
        (this.value.contentItem as ContentItemModel).label,
      ];
    }

    return [];
  }

  setHideTooltip(value: boolean) {
    this.hideTooltip = value;

    setTimeout(() => (this.hideTooltip = false), 100);
  }
}

export const LATEST = `m19.44396,9.054c-0.667,-3.5 -3.833,-6.167 -7.5,-6.167c-3.833,0 -6.833,2.667 -7.5,6.167c-2.5,0.5
 -4.5,2.833 -4.5,5.5c0,3.166 2.667,5.667 5.833,5.667l12.334,0c3.166,0 5.832,-2.501 
 5.832,-5.667c0.001,-2.667 -1.999,-5 -4.499,-5.5zm-1.334,8.001l-12.332,0c-1.5,0
  -2.667,-1.167 -2.667,-2.5c0,-1.167 0.833,-2.167 2,-2.333l2,-0.501l0.333,-2.167c0.5,-2 2.333,-3.5
   4.499,-3.5c2.167,0 4,1.5 4.334,3.5l0.332,2.167l2.167,0.501c1,0.166 2.001,1.166 2.001,2.333c0.001,1.333
    -1.167,2.5 -2.667,2.5z M10.444958716630936,13.053999900817871 8.277958899736404,11.553999900817871 6.944958716630936,13.720999717712402
     11.110959082841873,16.386999130249023 15.443958312273026,9.720999717712402 13.443958312273026,8.053999900817871`;

export const EARLY = `m11.94006,6.055c2.167,0 4,1.5 4.334,3.5l0.332,2.167l2.167,0.501c1,0.166 2.001,1.166 2.001,2.333c0,1.333 -1.168,2.5
 -2.668,2.5l-12.332,0c-1.5,0 -2.667,-1.167 -2.667,-2.5c0,-1.167 0.833,-2.167 2,-2.333l2,-0.501l0.333,-2.167c0.501,-2.001 2.334,-3.5 4.5,-3.5m0,-3.168c-3.833,0
  -6.833,2.667 -7.5,6.167c-2.5,0.5 -4.5,2.833 -4.5,5.5c0,3.166 2.667,5.667 5.833,5.667l12.334,0c3.166,0 5.832,-2.501 5.832,-5.667c0,-2.667
   -2,-5 -4.5,-5.5c-0.666,-3.5 -3.832,-6.167 -7.499,-6.167l0,0z`;

export const ARCHIVED = `M588.309,563.691H51.693c-19.692,0-34.461-9.846-44.308-24.615c-9.846-14.769-9.846-34.461,0-49.229L278.154,22.154
c19.692-29.539,68.923-29.539,83.691,0l270.771,467.692c9.846,14.771,9.846,34.462,0,49.229
C622.77,553.846,608.001,563.691,588.309,563.691z M135.385,465.23h369.231L317.539,150.153L135.385,465.23z`;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-node",class:{
    'children-hidden': !_vm.node.childrenVisible,
    'is-root': _vm.node.isRoot,
    'is-last': _vm.node.isLast,
    'is-selected': _vm.isSelected,
    'is-disabled': _vm.isInvalid || _vm.preventSelection,
    'is-invalid': _vm.isInvalid,
    'has-children': _vm.node.hasChildren,
    'previous-disabled': _vm.previousNodeDisabled,
  },style:({
    'padding-left': _vm.paddingLeft,
  })},[(!_vm.isInvalid)?_c('v-checkbox',{staticClass:"ma-0 tree-node__checkbox",attrs:{"color":"primary","ripple":false,"disabled":_vm.preventSelection},on:{"click":function($event){return _vm.select(_vm.isSelected)}},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.isInvalid,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({ref:"node",staticClass:"tree-node__item",on:{"click":function($event){return _vm.select(!_vm.isSelected)}}},'div',attrs,false),on),[(!_vm.node.isRoot)?_c('div',{staticClass:"tree-node__connector"}):_vm._e(),_c('div',{staticClass:"tree-node__toggle-btn-wrapper"},[(_vm.node.hasChildren)?_c('v-btn',{staticClass:"tree-node__toggle-btn",attrs:{"aria-label":"Toggle children","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleChildren($event)}}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.loadingChildren)?_c('span',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":16,"color":"grey","width":"2"}})],1):_c('span',[_c('v-icon',{staticClass:"tree-node__toggle-btn-icon"},[_vm._v(" mdi-chevron-right ")])],1)])],1):_vm._e()],1),_c('div',{staticClass:"body-2 text-left text-truncate tree-node__label"},[_vm._v(" "+_vm._s(_vm.node.label)+" ")]),(_vm.showStatusIcon && !_vm.isInvalid)?_c('status-icon',{attrs:{"status":_vm.node.publishingStatus}}):_vm._e()],1)]}}])},[(_vm.node.status === 'ARCHIVED')?_c('span',[_vm._v(" Node is archived ")]):_c('span',[_vm._v("Node is not a valid content type for addition")])]),_vm._l((_vm.nestingLevels),function(level){return _c('div',{key:level,staticClass:"tree-node__level",style:({
      left: _vm.getTreeLinePadding(level),
    })})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'is-last': _vm.store.isLast(_vm.value),
    'is-edit': _vm.isEdit,
    'is-new': !_vm.isEdit,
    'is-small': _vm.store.cardType === 'small',
  },attrs:{"elevation":"2","disabled":_vm.isDisabled}},[(_vm.isEdit)?_c('div',{staticClass:"card__image"},[_c('img',{attrs:{"src":require("@/assets/default_icon.png"),"alt":"content item"}})]):_vm._e(),_c('div',{staticClass:"card__scale"},[(_vm.isEdit)?_c('div',{staticClass:"txt-container"},[_c('h3',{attrs:{"ng-if":"value.contentItem.label"}},[_vm._v(" "+_vm._s(_vm.value.contentItem.label)+" ")]),_c('breadcrumbs',{attrs:{"items":_vm.value.path}})],1):_vm._e(),(!(_vm.value.isEmpty() && _vm.store.isLast(_vm.value)))?_c('span',{staticClass:"card__count"},[_vm._v(" "+_vm._s(_vm.value.index + 1)+" ")]):_vm._e()]),_c('div',{staticClass:"btn-container"},_vm._l((_vm.value.actions),function(action){return _c('v-tooltip',{key:action.label,attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",class:[((action.label) + "-btn")],attrs:{"fab":"","depressed":"","color":!_vm.store.isLast(_vm.value) && !_vm.isEdit ? 'white' : 'dark_grey'},on:{"click":action.action}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.label))])])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }





























































import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import { CardModel, EmptyItem } from "@/store/CardModel"; // eslint-disable-line no-unused-vars
import { ContentItemModel } from "@/store/FieldModel"; // eslint-disable-line no-unused-vars

import Visualization from "@/components/Visualization.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

import store from "@/store/DynamicContent";

@Observer
@Component({
  components: {
    Visualization,
    Breadcrumbs,
  },
})
export default class AmpCard extends Vue {
  public store = store;

  @Prop(Object) value!: CardModel;

  get isEdit() {
    return !this.value.isEmpty();
  }

  get isDisabled() {
    return this.value.index === this.store.maxItems;
  }
}

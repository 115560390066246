var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-sheet',{staticClass:"app",class:{
      'is-readonly': _vm.store.isReadOnly,
      'is-panel-open': _vm.store.panelOpen,
      'is-small': _vm.store.cardType === 'small',
      'is-large': _vm.store.cardType === 'large',
      'is-chip': _vm.store.cardType === 'chip',
      'is-dragging': _vm.isDragging,
    }},[_c('h1',{staticClass:"px-3 text-body-1"},[_vm._v(_vm._s(_vm.store.title))]),(!_vm.store.loading)?_c('draggable',{staticClass:"row",attrs:{"move":_vm.isDraggable,"handle":".is-edit:not(.is-last),.is-new:not(.is-last)"},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false}},model:{value:(_vm.store.listModel),callback:function ($$v) {_vm.$set(_vm.store, "listModel", $$v)},expression:"store.listModel"}},_vm._l((_vm.store.model),function(value){return _c('v-col',{key:value.index,attrs:{"cols":"auto"}},[(_vm.store.cardType !== 'chip')?_c('card',{attrs:{"value":value}}):_vm._e(),(_vm.store.cardType === 'chip')?_c('chip',{attrs:{"value":value}}):_vm._e()],1)}),1):_vm._e(),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":"","right":"","width":"95vw"},on:{"input":_vm.onPanelChange},model:{value:(_vm.store.panelOpen),callback:function ($$v) {_vm.$set(_vm.store, "panelOpen", $$v)},expression:"store.panelOpen"}},[(_vm.store.panelOpen)?_c('chooser-overlay',{on:{"add":_vm.add,"cancel":_vm.cancel}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }






























import { Component, Mixins } from "vue-property-decorator";
import { Observer } from "mobx-vue";

import { complement, equals, ifElse, isNil, when } from "ramda";
import TreeStore from "@/store/Tree";
import DynamicContentStore from "@/store/DynamicContent";
import Alert from "@/mixins/ShowAlert.mixin";
import TreeNode from "./TreeNode.vue";
import NodeTransition from "@/components/NodeTransition.vue";
import { notError } from "@/utils/helpers";
// eslint-disable-next-line no-unused-vars
import { HierarchyChildren, HierarchyNode } from "dc-management-sdk-js";
// eslint-disable-next-line no-unused-vars
import { ContentItemModel } from "@/store/FieldModel";

const loadTree: any = when(complement(isNil), TreeStore.loadTree);

@Observer
@Component({
  components: { NodeTransition, TreeNode },
})
export default class TreeView extends Mixins(Alert) {
  treeStore = TreeStore;

  created() {
    this.init();
  }

  async init() {
    when(equals(false), this.loadTree)(this.treeStore.treeLoaded);
  }

  async loadTree() {
    ifElse(notError, this.setTree, () => this.showAlert("Could not load tree"))(
      await loadTree(DynamicContentStore.getNodeId())
    );
  }

  setTree({
    children,
    ...node
  }: {
    children: HierarchyNode[];
    node: HierarchyNode;
  }) {
    TreeStore.setRootNode(node).setChildren(children);
    TreeStore.rootNode?.showChildren(true);
    TreeStore.setTreeLoaded(true);
  }
}

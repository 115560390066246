













import { prop } from "ramda";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ARCHIVED, EARLY, LATEST } from "@/assets/icons";

@Component
export default class StatusIcon extends Vue {
  @Prop({ required: true, type: String })
  status!: string;

  statuses = {
    ARCHIVED,
    EARLY,
    LATEST,
  };
  tooltips = {
    EARLY: "Published (early)",
    LATEST: "Published (latest)",
  };

  get icon() {
    //@ts-ignore
    return prop(this.status, this.statuses);
  }
  get tooltip() {
    //@ts-ignore
    return prop(this.status, this.tooltips);
  }
}














































import draggable, { MoveEvent } from "vuedraggable"; // eslint-disable-line no-unused-vars

import { equals } from "ramda";
import { Observer } from "mobx-vue";
import { Component, Vue } from "vue-property-decorator";

import Card from "@/components/Card.vue";
import Chip from "@/components/Chip.vue";
import Loading from "@/components/Loading.vue";
import ChooserOverlay from "@/components/ChooserOverlay.vue";

import TreeStore from "@/store/Tree";

import store, { CardType } from "@/store/DynamicContent"; // eslint-disable-line no-unused-vars

import { CardModel, EmptyItem } from "./store/CardModel"; // eslint-disable-line no-unused-vars
import { ContentItemModel } from "./store/FieldModel"; // eslint-disable-line no-unused-vars

@Observer
@Component({
  components: {
    Card,
    Chip,
    Loading,
    ChooserOverlay,
    draggable,
  },
})
export default class App extends Vue {
  public store = store;
  public tree = TreeStore;
  public isDragging = false;
  public originalModel!: Array<CardModel>;

  created() {
    this.init();
  }
  async init() {
    await store.initialize();
  }

  async onPanelChange(open: Boolean) {
    if (open) {
      const OVERLAY_HEIGHT = 500;
      this.store.autoSizeComponent(false);
      this.store.setComponentHeight(OVERLAY_HEIGHT);
      this.originalModel = [...this.store.model];
    } else {
      this.store.autoSizeComponent(true);
      this.tree.clearSelectedNodes();

      const model = this.store.exportModel(this.originalModel);
      const value = await this.store.dcExtensionSdk.field.getValue();

      if (equals(model, value)) {
        return;
      }

      try {
        await this.store.updateList(this.originalModel);
      } catch (err) {
        console.info("Invalid model value");
      }
    }
  }

  async add() {
    const nodes = this.tree.selectedNodes;
    const oldValues = this.store.model.map((node) => node.export());
    const newValues = nodes.map((node) => node.export()) as ContentItemModel[];

    if (!nodes.length) {
      return this.store.togglePanel();
    }

    try {
      const updatedValue = [oldValues, newValues]
        .flat()
        .filter((item) => !(item as EmptyItem)._empty);

      const model = await store.createModel(updatedValue);

      await this.store.updateList(model);
      this.originalModel = [...this.store.model];
    } catch (err) {
      this.originalModel = [...this.store.model];
      console.info("Invalid model value");
    } finally {
      this.store.togglePanel();
    }
  }

  cancel() {
    this.store.togglePanel();
  }

  isDraggable($event: MoveEvent<HTMLElement>) {
    const to = this.store.model[$event.draggedContext.futureIndex];
    const from = this.store.model[$event.draggedContext.index];

    return !(this.store.isLast(to) || this.store.isLast(from));
  }
}

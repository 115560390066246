












import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  // beforeEnter(el: HTMLElement) {}
  // enter(el: HTMLElement, done: Function) {}
  // beforeLeave(el: HTMLElement) {}
  // leave(el: HTMLElement, done: Function) {}
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-chip',{class:{
      'is-last': _vm.store.isLast(_vm.value),
      'is-edit': _vm.isEdit,
      'is-new': !_vm.isEdit,
    },attrs:{"small":"","close":!_vm.value.isEmpty(),"ripple":false,"disabled":_vm.isDisabled,"close-icon":"mdi-window-close"},on:{"click:close":function($event){return _vm.store.removeItem(_vm.value.index)},"click":function($event){!_vm.isEdit ? _vm.store.togglePanel() : _vm.noop}}},[(!_vm.value.isEmpty())?_c('v-tooltip',{attrs:{"content-class":"chip-tooltip","bottom":true,"disabled":_vm.hideTooltip,"open-on-click":false,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"mousedown":function($event){return _vm.setHideTooltip(true)}}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.value.contentItem.label))])]}}],null,true)},_vm._l((_vm.tooltip),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0):_vm._e(),(_vm.value.isEmpty() && !_vm.store.isLast(_vm.value))?_c('span',{staticClass:"v-chip__count"},[_vm._v(_vm._s(hover ? "Add" : _vm.value.index + 1))]):_vm._e(),(_vm.value.isEmpty() && _vm.store.isLast(_vm.value))?_c('span',{staticClass:"v-chip__add"},[_vm._v(" Add ")]):_vm._e(),_c('v-icon',{staticClass:"v-chip__add-icon"},[_vm._v("mdi-plus")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
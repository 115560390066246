


















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

import { ERROR_TYPE } from "@/store/Errors";

@Component
export default class ErrorBox extends Vue {
  @Prop({ type: String, default: ERROR_TYPE.UNKNOWN }) message!: string;

  @Emit("action")
  action(e: Event) {
    return e;
  }
}
